var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
import LinkItem from './LinkItem';
// ----------------------------------------------------------------------
export default function CustomBreadcrumbs(_a) {
    var links = _a.links, action = _a.action, heading = _a.heading, moreLink = _a.moreLink, activeLast = _a.activeLast, sx = _a.sx, other = __rest(_a, ["links", "action", "heading", "moreLink", "activeLast", "sx"]);
    var lastLink = links[links.length - 1].name;
    return (_jsxs(Box, __assign({ sx: __assign({ mb: 5 }, sx) }, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center" }, { children: [_jsxs(Box, __assign({ sx: { flexGrow: 1 } }, { children: [heading && (_jsx(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: heading }))), !!links.length && (_jsx(Breadcrumbs, __assign({ separator: _jsx(Separator, {}) }, other, { children: links.map(function (link) { return (_jsx(LinkItem, { link: link, activeLast: activeLast, disabled: link.name === lastLink }, link.name || '')); }) })))] })), action && _jsxs(Box, __assign({ sx: { flexShrink: 0 } }, { children: [" ", action, " "] }))] })), !!moreLink && (_jsx(Box, __assign({ sx: { mt: 2 } }, { children: moreLink.map(function (href) { return (_jsx(Link, __assign({ noWrap: true, href: href, variant: "body2", target: "_blank", rel: "noopener", sx: { display: 'table' } }, { children: href }), href)); }) })))] })));
}
// ----------------------------------------------------------------------
function Separator() {
    return (_jsx(Box, { component: "span", sx: { width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' } }));
}
